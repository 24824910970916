// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  openWeatherAddress: `https://api.openweathermap.org/data/2.5/weather?`,

  openWeatherKey: `&appid=61bdef27468d464fcf04c98c4077a85d`,
  googleMapsApiKey: `AIzaSyDk874KpYCtWPCf2Cff5z-5yWxp8IKHozc`
};

/*
  openWeatherKey: `&appid=Your_OpenWeather_API_Key`,
  googleMapsApiKey: `Your_Google_Places_API_Key`
  
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
